import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectsGrid from "../components/Projects";
import styled from "styled-components";

import { H1, H2, H3, MediumText } from "../components/styles/TextStyles";

const Projects = () => (
  <Layout>
    <SEO title="Projects" />
    <PageTitle>Projects</PageTitle>
    <ProjectsGrid></ProjectsGrid>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default Projects;

const PageTitle = styled(H2)`
  text-align: center;
`;
